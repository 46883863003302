export enum NavSectionId {
  Features = "features",
  Integrations = "integrations",
  Pricing = "pricing",
  FAQ = "faq",
  WindyChats = "windy-chats",
  Hero = "hero",
  Customers = "customers",
  Testimonials = "testimonials",
  Security = "security",
  HowItWorks = "how-it-works",
}

export const NAV_SECTIONS = [
  {
    id: NavSectionId.WindyChats,
    title: "Meet Windy",
  },
  {
    id: NavSectionId.Features,
    title: "Features",
  },
  {
    id: NavSectionId.Testimonials,
    title: "Testimonials",
  },
  {
    id: NavSectionId.Customers,
    title: "Customers",
  },
];
