import { Link } from "@remix-run/react";

import { cn } from "@wind/ui";

interface Props {
  children: React.ReactNode;
  to: string;
  className?: string;
}

export const CallToAction = ({ children, to, className }: Props) => (
  <Link
    to={to}
    className={cn("px-6 py-2 rounded-full text-lg font-medium transition-all", className)}
  >
    {children}
  </Link>
);

export const PrimaryCta = ({ children, to, className }: Props) => (
  <CallToAction
    to={to}
    className={cn(
      "bg-highlight bg-gradient-to-b from-highlight/80 to-highlight hover:bg-highlight text-accent-inverse border border-highlight",
      className
    )}
  >
    {children}
  </CallToAction>
);

export const SecondaryCta = ({ children, to, className }: Props) => (
  <CallToAction
    to={to}
    className={cn(
      "bg-gradient-to-b from-white/70 to-white/50 hover:bg-white border border-white",
      className
    )}
  >
    {children}
  </CallToAction>
);
