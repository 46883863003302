import { Link } from "@remix-run/react";
import { motion, useScroll, useTransform } from "framer-motion";

import {
  cn,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  useCurrentPath,
} from "@wind/ui";
import { DropdownMenu, DropdownMenuTrigger } from "@wind/ui/shadcn";

import WindmillWordmark from "~/components/marketing/WindmillWordmark.js";
import { useOptionalAuthUser } from "~/hooks/useAuthUser.js";
import { PAGE_LINKS } from "~/marketing/data/navigation.js";
import { ChevronDownIcon, ExternalLinkIcon, HamburgerIcon } from "~/utils/icons.js";
import { NAV_SECTIONS } from "../data/sections.js";
import useStartUrl from "../hooks/useStartUrl.js";
import { PrimaryCta } from "./CallToAction.js";

const handleSmoothScroll = (id: string) => (e: React.MouseEvent) => {
  e.preventDefault();
  const element = document.getElementById(id);
  if (element) {
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
    window.requestAnimationFrame(() => {
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    });
  }
};

const MenuDropdownContent = ({
  isOnHomePage,
  showNavSections,
  showLoginLink,
}: {
  isOnHomePage: boolean;
  showNavSections?: boolean;
  showLoginLink?: boolean;
}) => {
  const user = useOptionalAuthUser();

  return (
    <DropdownMenuContent
      className="bg-white/50 backdrop-blur-lg border-none"
      side="bottom"
      alignOffset={30}
      align="end"
    >
      {showLoginLink && (
        <>
          {user ? (
            <DropdownMenuItem>
              <Link to="/s">Open Windmill</Link>
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem>
              <Link to="/auth">Login</Link>
            </DropdownMenuItem>
          )}
          <DropdownMenuSeparator />
        </>
      )}
      {showNavSections && (
        <>
          {isOnHomePage
            ? NAV_SECTIONS.map((section) => (
                <DropdownMenuItem key={section.id} onClick={handleSmoothScroll(section.id)}>
                  {section.title}
                </DropdownMenuItem>
              ))
            : NAV_SECTIONS.map((section) => (
                <DropdownMenuItem key={section.id} asChild>
                  <Link to={"/#" + section.id}>{section.title}</Link>
                </DropdownMenuItem>
              ))}
          <DropdownMenuSeparator />
        </>
      )}
      {PAGE_LINKS.map((link) => (
        <DropdownMenuItem key={link.name} asChild>
          <Link
            to={link.href}
            className="flex items-center justify-between"
            target={link.isExternal ? "_blank" : undefined}
          >
            {link.name}
            {link.isExternal && <ExternalLinkIcon className="text-tertiary" />}
          </Link>
        </DropdownMenuItem>
      ))}
    </DropdownMenuContent>
  );
};

const MAIN_NAV_CLASSNAME =
  "px-4 py-2 text-sm lg:text-base rounded-full hover:bg-gradient-to-b from-white/70 to-white/30 transition-all font-medium";

const TopNavSectionLink = ({
  section,
  isOnHomePage,
}: {
  section: (typeof NAV_SECTIONS)[number];
  isOnHomePage: boolean;
}) => {
  if (isOnHomePage) {
    return (
      <button onClick={handleSmoothScroll(section.id)} className={MAIN_NAV_CLASSNAME}>
        {section.title}
      </button>
    );
  } else {
    return (
      <Link to={"/#" + section.id} className={MAIN_NAV_CLASSNAME}>
        {section.title}
      </Link>
    );
  }
};

const TopNav = () => {
  const { scrollY } = useScroll();
  const transitionProgress = useTransform(scrollY, [10, 100], [0, 1]);
  const backgroundColor = useTransform(transitionProgress, [0, 1], ["#FFFFFF00", "#d2e1fd99"]);
  const currentPath = useCurrentPath();
  const isOnHomePage = currentPath === "/";
  const user = useOptionalAuthUser();
  const opacity = useTransform(scrollY, [500, 600], [0, 1]);
  const startUrl = useStartUrl();

  return (
    <div className="sticky top-0 flex items-center justify-center z-20 h-16 overflow-hidden">
      <motion.div
        className="flex items-center w-full relative backdrop-blur-lg justify-between h-16"
        style={{
          backgroundColor,
        }}
      >
        <div className="px-4 xl:px-8 items-center flex h-full w-1/2 xl:w-1/4">
          <Link to="/">
            <WindmillWordmark className="h-8 lg:h-10" />
          </Link>
        </div>
        <div className="gap-4 py-4 px-2 w-1/2 items-center justify-center hidden xl:flex">
          {NAV_SECTIONS.map((section) => (
            <TopNavSectionLink key={section.id} section={section} isOnHomePage={isOnHomePage} />
          ))}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <button className={cn(MAIN_NAV_CLASSNAME, "flex items-center gap-2")}>
                More <ChevronDownIcon />
              </button>
            </DropdownMenuTrigger>
            <MenuDropdownContent isOnHomePage={isOnHomePage} />
          </DropdownMenu>
        </div>
        <div className="flex justify-end h-full items-center w-1/2 xl:w-1/4 px-4 xl:px-8">
          <motion.div style={{ opacity }}>
            <PrimaryCta to={startUrl} className="mr-4 py-1 px-3 text-base">
              Demo
            </PrimaryCta>
          </motion.div>
          <div className="xl:hidden">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <button className={cn(MAIN_NAV_CLASSNAME, "flex items-center gap-2")}>
                  Menu <HamburgerIcon />
                </button>
              </DropdownMenuTrigger>
              <MenuDropdownContent isOnHomePage={isOnHomePage} showNavSections showLoginLink />
            </DropdownMenu>
          </div>
          <div className="hidden xl:flex">
            {!user && (
              <Link
                to="/auth"
                className="hover:underline underline-offset-4 font-medium flex gap-2 items-center"
              >
                Login
                <ExternalLinkIcon />
              </Link>
            )}
            {user && (
              <Link
                to="/s"
                className="hover:underline underline-offset-4 font-medium flex gap-2 items-center"
              >
                Open Windmill
                <ExternalLinkIcon />
              </Link>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default TopNav;
