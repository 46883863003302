import { Link } from "@remix-run/react";

import { FlexibleSpacer } from "@wind/ui";

import WindmillWordmark from "~/components/marketing/WindmillWordmark.js";
import Icon from "~/icons/components/Icon.js";
import { TAGLINE_SUBTITLE } from "../data/copy.js";
import { LEGAL_LINKS, PAGE_LINKS, SOCIAL_LINKS, SUPPORT_LINKS } from "../data/navigation.js";
import useStartUrl from "../hooks/useStartUrl.js";
import { PrimaryCta } from "./CallToAction.js";

const FOOTER_NAVS = [
  {
    title: "Support",
    links: SUPPORT_LINKS,
  },
  {
    title: "Company",
    links: PAGE_LINKS,
  },
  {
    title: "Legal",
    links: LEGAL_LINKS,
  },
];

const Footer = () => {
  const startUrl = useStartUrl();

  return (
    <div className="px-2 md:px-4 lg:px-8 pb-2 md:pb-4 lg:pb-8">
      <footer className="pt-48 -mt-48 bg-gradient-to-b from-transparent via-white/70 to-white rounded-b-[2rem] gap-8 pb-24">
        <div className="px-8 lg:px-12 w-full max-w-screen-lg mx-auto">
          <div className="flex mt-48 flex-col lg:flex-row gap-8">
            <div className="flex flex-col gap-4 items-start max-w-sm">
              <WindmillWordmark className="w-48" />
              <p className="text-secondary mt-4 text-balance">{TAGLINE_SUBTITLE}</p>
              <PrimaryCta to={startUrl} className="mt-8">
                Get a demo
              </PrimaryCta>
            </div>
            <FlexibleSpacer />
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-20">
              {FOOTER_NAVS.map((nav, i) => (
                <div key={i}>
                  <div className="text-tertiary font-medium">{nav.title}</div>
                  <div className="flex flex-col gap-4 mt-4">
                    {nav.links.map((link) => (
                      <Link
                        key={link.name}
                        to={link.href}
                        className="hover:underline underline-offset-4"
                      >
                        {link.name}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-20 flex justify-between">
            <p className="text-secondary">© 2025 Windmill</p>
            <div className="flex gap-4">
              {SOCIAL_LINKS.map((link) => (
                <Link key={link.name} to={link.href} target="_blank" rel="noopener noreferrer">
                  <Icon icon={{ icon: link.icon }} size="md" />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
