import { StandardIconType } from "@wind/common-interfaces/icons";

import { LINKEDIN_URL, TWITTER_URL } from "./socials.js";

interface NAV_LINK {
  name: string;
  href: string;
  isExternal?: boolean;
}

interface NAV_LINK_WITH_ICON extends NAV_LINK {
  icon: StandardIconType;
}

export const SUPPORT_LINKS: NAV_LINK[] = [
  { name: "Contact Us", href: "mailto:support@gowindmill.com" },
  { name: "Help Desk", href: "https://help.gowindmill.com/en/" },
  { name: "Status", href: "https://windmill.betteruptime.com/" },
];

export const PAGE_LINKS: NAV_LINK[] = [
  { name: "About Us", href: "/about-us" },
  { name: "Integrations", href: "/integrations" },
  { name: "Stats", href: "/stats" },
  { name: "Pricing", href: "/pricing" },
  { name: "Blog", href: "https://blog.gowindmill.com", isExternal: true },
  { name: "Jobs", href: "https://jobs.ashbyhq.com/windmill", isExternal: true },
];

export const LEGAL_LINKS: NAV_LINK[] = [
  { name: "Terms of service", href: "/p/terms-of-service" },
  { name: "Privacy policy", href: "/p/privacy-policy" },
  {
    name: "Trust Site",
    href: "https://app.drata.com/trust/7bd6416b-c1ac-4c6c-afb4-a015fe83db6b",
    isExternal: true,
  },
];

export const SOCIAL_LINKS: NAV_LINK_WITH_ICON[] = [
  { name: "X", href: TWITTER_URL, icon: StandardIconType.TWITTER },
  { name: "LinkedIn", href: LINKEDIN_URL, icon: StandardIconType.LINKED_IN },
];
